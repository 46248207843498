<template>
  <div>
    <b-modal id="modal-add-new-param" cancel-variant="outline-secondary" :ok-title="$t('Add')" :cancel-title="$t('Close')"
      ok-variant="success" centered modal-class="modal-success" :title="$t('Add A New Param')" @ok="confirmAdd"
      @hidden="resetModal()">
      <!-- form -->
      <validation-observer ref="AddNewParamModalRef">
        <b-form method="POST" class="auth-reset-password-form mt-2" @submit.prevent="validationForm">
          <b-form-group label-for="key">
            <label class="mb-1">{{ $t("Key") }}</label>
            <validation-provider #default="{ errors }" name="key" rules="required">
              <b-form-input id="key" v-model="newParam.key" rows="4"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="value">
            <label class="mb-1">{{ $t("Value") }}</label>
            <validation-provider #default="{ errors }" name="value" rules="required">
              <b-form-textarea id="value" v-model="newParam.value" rows="4"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {},
  data() {
    return {
      newParam: {
        key: "",
        value: ""
      },
    };
  },
  methods: {
    validationForm() {
      this.$refs.AddNewParamModalRef.validate().then((success) => {
        if (success) {
          this.addNewParam(this.newParam);
        }
      });
    },
    async addNewParam(newParam) {
      await this.$store
        .dispatch("setting/addParam", newParam)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$bvModal.hide("modal-add-new-param");
                  this.resetModal();
                }, "500");
              });
              this.$emit("refetch-data");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    resetModal() {
      this.newParam = {
        key: "",
        value: ""
      };
    },
    confirmAdd(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    return {};
  },
};
</script>